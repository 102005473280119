import React from "react";



const PersonalInfoCardA = ({birthdate, age, civil_status, gender, contact_number, email})=>{
	return (
		<div className="row theme-cardify card-expand">
			<h5>Personal Information</h5>
			<div className="col-md-3 pt-2">
				<div className="pt-1 pb-1">
					<span className="fw-light">Birthdate: </span>
					<br></br>
					<p className="fw-semibold">{birthdate}</p>
				</div>

				<div className="pt-1 pb-1">
					<span className="fw-light">Age: </span>
					<br></br>
					<p className="fw-semibold">{age}</p>
				</div>
			</div>


			<div className="col-md-3 pt-2">
				<div className="pt-1 pb-1">
					<span className="fw-light">Civil Status: </span>
					<br></br>
					<p className="fw-semibold">{civil_status}</p>
				</div>

				<div className="pt-1 pb-1">
					<span className="fw-light">Gender: </span>
					<br></br>
					<p className="fw-semibold">{gender}</p>
				</div>
			</div>


			<div className="col-md-6 pt-2">
				<div className="pt-1 pb-1">
					<span className="fw-light">Contact Number: </span>
					<br></br>
					<p className="fw-semibold">{contact_number}</p>
				</div>

				<div className="pt-1 pb-1">
					<span className="fw-light">Email: </span>
					<br></br>
					<p className="fw-semibold">{email}</p>
				</div>
			</div>
		</div>
	);
}


export default PersonalInfoCardA;