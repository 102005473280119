import React, { useState, useEffect } from 'react';
import { getRequest, postRequest, deleteRequest, updateRequest } from "../auth";
import Calendar from 'react-calendar';
import Swal from 'sweetalert2';
import 'react-calendar/dist/Calendar.css';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

import Backdrop from '@mui/material/Backdrop';
import LoadingModal from "../components/loading_modal";





const ShiftManagementPanel = () => {
	const [is_loading, setIsLoading] = useState(false);

	const [inspecting_shift_id, setInspectingShiftId] = useState();
	const [inspecting_dict_id, setInspectingDictId] = useState();

	const [inp_shift_name, setInpShiftName] = useState("");
	const [inp_start_time, setInpStartTime] = useState("");
	const [inp_end_time, setInpEndTime] = useState("");
	const [inp_day_of_week, setInpDayOfWeek] = useState("");


	const [shifts, setShifts] = useState();


	// handle MUI states

	const [edit_shift_toggle, setEditShiftToggle] = useState(false);
	
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const handleClick = (event, assigned_id, dict_key) => {
		setInspectingShiftId(assigned_id)
		setAnchorEl(event.currentTarget);
		setInspectingDictId(dict_key)
	};
	const handleClose = () => {
		setAnchorEl(null);
	};


	const getShifts = async()=>{
		await getRequest("/workshift").then(response=>{
			if(response.status === 200){
				setShifts(response.data);
			}
		}).catch(error=>{
			Swal.fire({
				icon: "error",
				title: "Error",
				text: "Error: " + error,
			});
		});
	}


	const handleDelete = (e)=>{
		e.preventDefault();
		setIsLoading(true);
		handleClose();

		const deleteShift = async()=>{
			await deleteRequest("/workshift/?id=" + inspecting_shift_id).then(response=>{
				if(response.status === 200){
					Swal.fire({
						icon: "success",
						title: "Delete Complete",
						text: "Shift was successfully deleted",
					});

					getShifts();
					setIsLoading(false);
				}
			}).catch(error=>{
				setIsLoading(false);
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Error: " + error,
				});
			})
		}

		setIsLoading(false);
		Swal.fire({
			title: "Delete Shift?",
			showCancelButton: true,
			confirmButtonText: "Delete",
		}).then((result) => {
			if (result.isConfirmed) {
				deleteShift();
			}
		});
	}


	const hadleUpdateShift = async(e)=>{
		e.preventDefault();

		let data = {};

		if(inp_shift_name !== null){
			data["name"] = inp_shift_name
		}
		if(inp_start_time !== null){
			data["start_time"] = inp_start_time
		}
		if(inp_end_time !== null){
			data["end_time"] = inp_end_time
		}
		if(inp_day_of_week !== null){
			data["day_of_week"] = inp_day_of_week
		}
		
		
		setEditShiftToggle(false);
		await updateRequest("/workshift/?id=" + inspecting_shift_id, data).then(response=>{
			if(response.status === 200){
				Swal.fire({
					icon: "success",
					title: "Update Complete",
					text: "Shift was successfully updated",
				});

				getShifts();
			}
		}).catch(error=>{
			Swal.fire({
				icon: "error",
				title: "Error",
				text: "Error: " + error,
			});
		});
	}


	const openEditShiftModal = (e)=>{
		e.preventDefault();
		handleClose();

		// Reset Field Values
		setInpShiftName(null);
		setInpStartTime(null);
		setInpEndTime(null);
		setInpDayOfWeek(null);

		document.querySelector("#edit-name-field").value = shifts[inspecting_dict_id]["name"];
		document.querySelector("#edit-start-time-field").value = shifts[inspecting_dict_id]["start_time"];
		document.querySelector("#edit-end-time-field").value = shifts[inspecting_dict_id]["end_time"];
		document.querySelector("#edit-day-field").value = shifts[inspecting_dict_id]["day_of_week"];

		setEditShiftToggle(true);
	}



	useEffect(()=>{
		getShifts();
	}, []);


	// Submit the new shift to the database
	const submitPost = async(data)=>{
		await postRequest("/workshift/", data).then(response => {
			if(response.status === 201){
				Swal.fire({
					icon: "success",
					title: "Completed",
					text: "New Shift added",
				});

				getShifts();
			} 
		}).catch(error => {
			Swal.fire({
				icon: "error",
				title: "Error",
				text: "Error: " + error,
			});
		})
	}	



	// Handle sumbit new shift button clicked 
	const submitNewShift = (e)=>{
		e.preventDefault();
		let is_data_complete = true;

		const data = {
			name : inp_shift_name,
			day_of_week : inp_day_of_week,
			start_time : inp_start_time,
			end_time : inp_end_time
		}


		Object.keys(data).map(key=>{
			if(data[key] === null || data[key] === undefined || data[key] === ""){
				is_data_complete = false
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Please Complete the form",
				});
			}
			return null;
		});

		if(is_data_complete === true){
			submitPost(data);
		}
	}



	const handleDuplicate = (e)=>{
		e.preventDefault();

		const data = {
			name : shifts[inspecting_dict_id]["name"],
			day_of_week : shifts[inspecting_dict_id]["day_of_week"],
			start_time : shifts[inspecting_dict_id]["start_time"],
			end_time : shifts[inspecting_dict_id]["end_time"]
		}

		console.log(data);
		submitPost(data);
	}





	return (
		<div className="container-fluid">
			<LoadingModal is_loading={is_loading} />
			<div className="row">
				<div className="col-md-12">
					<p className="theme-section-title title-md">Shift Management</p>
				</div>
			</div>

			<div className="row mt-3">
				<div className="col-md-6">
					<label className="form-label">Shift Name:</label>
					<input className="form-control" type="text" onChange={(e)=>(setInpShiftName(e.target.value))} required></input>

					<label className="form-label">Start Time:</label>
					<input className="form-control" type="time" name="start-time" onChange={(e)=>(setInpStartTime(e.target.value))} required></input>

					<label className="form-label">End Time:</label>
					<input className="form-control" type="time" name="end-time" onChange={(e)=>(setInpEndTime(e.target.value))} required></input>

					<label className="form-label">Select Day</label>
					<select class="form-select" aria-label="Default select example" onChange={(e)=>(setInpDayOfWeek(e.target.value))} required>
						<option selected>Open this select menu</option>
						<option value="Monday">Monday</option>
						<option value="Tuesday">Tuesday</option>
						<option value="Wednesday">Wednesday</option>
						<option value="Thursday">Thursday</option>
						<option value="Friday">Friday</option>
						<option value="Saturday">Saturday</option>
						<option value="Sunday">Sunday</option>
					</select>

					<button type="submit" className="theme-btn btn-md mt-3" onClick={(e)=>(submitNewShift(e))}>Create Shift</button>
				</div>

				<div className="col-md-6 d-flex align-items-center justify-content-center">
					<Calendar />
				</div>
			</div>

			<div className="row mt-5">
				<div className="col-md-12">
					<p className="theme-section-title title-md">Created Shifts</p>
				</div>
			</div>

			<div className="row mt-3">
				<div className="col-md-12 d-flex flex-wrap justify-content-around">
					{!shifts && "LOADING..."}


					{
						shifts && Object.keys(shifts).map(key=>{
							return <div className="theme-cardify ml-1 mr-1 card-nm mt-1">
								<div className="row">
									<div className="col-md-10 d-flex align-items-start justify-content-start">
										<p className="theme-section-title title-nm mb-2">{shifts[key]["name"]}</p>
									</div>
									<div className="col-md-2 d-flex align-items-end justify-content-end">

										<Button
											id="basic-button"
											aria-controls={open ? 'basic-menu' : undefined}
											aria-haspopup="true"
											aria-expanded={open ? 'true' : undefined}
											onClick={(e)=>(handleClick(e, shifts[key]["id"], key))}
										>
											<span class="material-symbols-outlined">more_vert</span>
										</Button>

										<Menu
											id="basic-menu"
											anchorEl={anchorEl}
											open={open}
											onClose={handleClose}
											MenuListProps={{
											'aria-labelledby': 'basic-button',
										}}
											> 
											<MenuItem onClick={(e)=>(openEditShiftModal(e))}>Edit</MenuItem>
											<MenuItem onClick={(e)=>(handleDelete(e))}>Delete</MenuItem>
											<MenuItem onClick={(e)=>(handleDuplicate(e))}>Duplicate</MenuItem>
										</Menu>

									</div>
								</div>


								<div className="row">
									<div className="col-md-12">
										<div className="">
											<p>
												<h5>{shifts[key]["day_of_week"]} </h5> - From: {shifts[key]["start_time"]} To {shifts[key]["end_time"]}
											</p>
										</div>
									</div>
								</div>
							</div>
						})
					}
					
				</div>
			</div>



			{/**  BACKDROPS  **/}
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={edit_shift_toggle}
			>	
				<div className="container">
					<div className="row mt-3">
						<div className="col-md-4"></div>
						<div className="col-md-4 theme-bg dark p-4 rounded-corner">

							<div className="row">
								<div className="col-md-6 d-flex align-items-center justify-content-start">
									<p className="theme-section-title title-nm theme-color">Edit Shift</p>
								</div>

								<div className="col-md-6 d-flex align-items-center justify-content-end">
									<button type="button" onClick={(e)=>setEditShiftToggle(false)} class="theme-color theme-btn-no-style" data-bs-dismiss="modal" aria-label="Close">
										<span class="material-symbols-outlined theme-color">close</span>
									</button>
								</div>
							</div>

							<div className="row mt-4">
								<div className="col-md-12">

									<label className="form-label">Shift Name:</label>
									<input className="form-control" type="text" onChange={(e)=>(setInpShiftName(e.target.value))} id="edit-name-field"></input> 

									<label className="form-label">Start Time:</label>
									<input className="form-control" type="time" name="start-time" onChange={(e)=>(setInpStartTime(e.target.value))} id="edit-start-time-field"></input>

									<label className="form-label">End Time:</label>
									<input className="form-control" type="time" name="end-time" onChange={(e)=>(setInpEndTime(e.target.value))} placeholder={edit_shift_toggle && shifts[inspecting_dict_id]["end_time"]} id="edit-end-time-field"></input>

									<label className="form-label">Select Day</label>
									<select class="form-select" aria-label="Default select example" onChange={(e)=>(setInpDayOfWeek(e.target.value))} placeholder={edit_shift_toggle && shifts[inspecting_dict_id]["day_of_week"]} id="edit-day-field">

										<option selected>Open this select menu</option>
										<option value="Monday">Monday</option>
										<option value="Tuesday">Tuesday</option>
										<option value="Wednesday">Wednesday</option>
										<option value="Thursday">Thursday</option>
										<option value="Friday">Friday</option>
										<option value="Saturday">Saturday</option>
										<option value="Sunday">Sunday</option>
									</select>

									<button className="theme-btn btn-md mt-3" onClick={(e)=>(hadleUpdateShift(e))}>Edit Shift</button>
								</div>
							</div>
							
						</div>
						<div className="col-md-4"></div>
					</div>
				</div>
				
			</Backdrop>
		</div>
	)
}

export default ShiftManagementPanel;

