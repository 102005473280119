import { useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import { useHRISContext } from "./context";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.withCredentials = true;


const tk_name = "p6z048ndi77oc0c";




//const base_url = "http://127.0.0.1:8000";
const base_url = "https://luminousdevs.pythonanywhere.com/";


const client_api = axios.create({
	baseURL: base_url,
});




const login = async (email, password, navigate)=>{
	await client_api.post(
		"/get-token/",
		{
			username : email,
			password : password
		}
	).then(response=>{
		const _encrypted = response.data.token;
		Cookies.set(tk_name, _encrypted, { expires: 1 });
		navigate("/dashboard/admin", {});

	}).catch(error=>{
		Swal.fire({
			icon: "error",
			title: "Login Failed",
			text: "Wrong Credentials",
		});
	});
}




const logout = async()=>{
	Cookies.remove(tk_name);
}



const getRequest = async (api_uri) => {
	const auth_tk = Cookies.get(tk_name);
	const api_request = await client_api.get(api_uri, {
	  headers: {
	    Authorization: `Token ${auth_tk}`,
	  },
	});
	return api_request;
}




const postRequest = async (api_uri, data) => {
	const auth_tk = Cookies.get(tk_name);
	const api_request = await client_api.post(api_uri, data, {
	  headers: {
	  	"Content-Type"	: "multipart/form-data",
	    Authorization	: `Token ${auth_tk}`,
	  },
	});
	return api_request;
}


const updateRequest = async (api_uri, data) => {
	const auth_tk = Cookies.get(tk_name);
	const api_request = await client_api.put(api_uri, data, {
	  headers: {
	    Authorization: `Token ${auth_tk}`,
	  },
	});
	return api_request;
}


const deleteRequest = async (api_uri) => {
	const auth_tk = Cookies.get(tk_name);
	const api_request = await client_api.delete(api_uri, {
	  headers: {
	    Authorization: `Token ${auth_tk}`,
	  },
	});
	return api_request;
}



const useAuthVerify = ()=>{
	const location = useLocation();
  	const currentPath = location.pathname;
  	const navigate = useNavigate();
  	const {setId, setUserName, setAdminStatus} = useHRISContext();

	useEffect(()=>{
		const request_auth = async()=>{
			const auth_tk = Cookies.get(tk_name);
			const api_response = await client_api.get('/auth-verify/', {
			  headers: {
			    Authorization: `Token ${auth_tk}`,
			  },
			}).then(response=>{
				if(response.status === 200){
					setId(response.data.user_id);
					setUserName(response.data.email);
					setAdminStatus(response.data.is_admin);

					if(currentPath === "/login"){
						navigate("/dashboard/admin", {});
					} 
				}
			}).catch(err=>{
				if(currentPath !== "/login" && currentPath !== "/debug"){
					logout()
					navigate("/login", {});
				}
			});
			return api_response;
		}

		const response = request_auth()
		return ()=>{
			if(response.response === "bad"){
				return
			}
		}
	});
}












export { login, logout, getRequest, postRequest, updateRequest, deleteRequest, useAuthVerify, base_url }