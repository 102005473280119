import React, { useState } from "react";
import { getRequest } from "../auth";
import Swal from 'sweetalert2';
//import Backdrop from '@mui/material/Backdrop';
import LoadingModal from "../components/loading_modal";
import EmptySearchIllustration from "../assets/empty_search_illustration.svg";
import ProfileSearchResultCard from "../components/profile_search_result_card";
import UserProfileView from "./panel_section_component/user_profile_view";





const EmployeeAccountManagerPanel = ()=>{

	/**************** Stateful Variables ****************/
	const [querying, setQuerying] = useState(false);
	const [search_results, setSearchResults] = useState(null);
	const [show_profile_view_id, setShowProfileViewId] = useState(null);




	/**************** Getters ****************/
	const getSearchResult = async (e)=>{
		e.preventDefault();
		setShowProfileViewId(null);
		setSearchResults(null)
		setQuerying(true);
		const search_query = document.querySelector("#search_query_field").value;

		await getRequest("/search/name/?name=" + search_query).then(response=>{
			if(response.status === 200){
				setSearchResults(response.data);
				setQuerying(false);
			} else if(response.status === 204){
				Swal.fire({
					icon: "warning",
					title: "0 Search Result",
					text: "No employee found",
				});
				setQuerying(false);
			}
		}).catch(error=>{
			Swal.fire({
				icon: "error",
				title: "Error",
				text: "Error occured:" + error,
			});
			setQuerying(false);
		});
	}




	/**************** Callback Functions ****************/
	const viewProfileClicked = async(data_id)=>{
		setShowProfileViewId(data_id);
	}




	return (
		<div className="container-fluid">
			<LoadingModal is_loading={querying}/>
			<div className="row mt-3">
				<div className="col-md-6">
					<p className="theme-section-title title-md">User Account Management</p>
				</div>

				<div className="col-md-6">
					<div className="row">
						<div className="col-md-12 d-flex">
							<input type="text" className="form-control flex-grow-1" id="search_query_field" placeholder="Search Employee"></input>
							
							<button className="theme-btn btn-sm" onClick={(e)=>(getSearchResult(e))}>
								{querying ? "Searching..." : "Search"}
							</button>
						</div>
					</div>
				</div>
			</div>



			{	/** Illustration shows thatt there is no query yet **/
				search_results === null &&
				<div className="row mt-5">
					<div className="col-md-12 d-flex align-items-center justify-content-center">
						<img src={EmptySearchIllustration} alt="no query" className="theme-illustratrion-md"></img>
					</div>
				</div>
			}


			{/************* SHOW RESULTS CARDS *************/}
			<div className="row mt-3">
				{
					show_profile_view_id === null && search_results && Object.keys(search_results).map(key=>{
						let full_name = search_results[key]["first_name"] + " " + search_results[key]["middle_name"] + ", " + search_results[key]["last_name"];
						return  <div className="col-md-3 d-flex flex-row justify-content-center align-items-center mt-3">
										<ProfileSearchResultCard profile_image={search_results[key]["profile_image"]} name={full_name} job_title={search_results[key]["job_title_name"]} function_call={viewProfileClicked} card_data_id={search_results[key]["id"]}/>
								</div>
					})
				}
			</div>
			{
				show_profile_view_id !== null &&

				<div className="row mt-3">
					<div className="col-md-12">
						<UserProfileView data_id = {show_profile_view_id}/>
					</div>
				</div>
			}
			
			


			


		</div>
	);
}


export default EmployeeAccountManagerPanel;