import React from "react";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


const LoadingModal = ({is_loading})=>{
	return(
		<Backdrop
		sx={{ color: '#6a48b5', zIndex: (theme) => theme.zIndex.drawer + 1 }}
		open={is_loading}
		>
			<CircularProgress color="inherit" />
		</Backdrop>
	);
}


export default LoadingModal;