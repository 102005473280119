import React from 'react';

import IconedButtonV from "../components/iconed_btn_v";
import "../stylesheets/classic_theme.css";


const MoreOptionsPanel = ()=>{
	return (
		<div className="container-fluid">
			<div className="row">


				<div className="col-md-6 d-flex align-items-start justify-content-md-start">
					<div className="theme-cardify card-expand">
						<p className="theme-section-title title-nm mb-3">Employee Management</p>
						<div className="d-flex flex-wrap">

							{/**<IconedButtonV icon={"person_add"} hint={"Register New Employee"} label={"New Employee"} action={"/registration"} extra_class={"margined-side iconed-btn-lg label-md flex-fill"}/>**/}
							<IconedButtonV icon={"manage_accounts"} hint={"Create Employee Account"} label={"Employee Account"} action={"/manage/employee"} extra_class={"margined-side iconed-btn-lg label-md flex-fill"}/>
							<IconedButtonV icon={"diversity_3"} hint={"View All Employee"} label={"Employees List"} action={"/employee-list"} extra_class={"margined-side iconed-btn-lg label-md flex-fill"}/>
							<IconedButtonV icon={"work"} hint={"Create Job Title"} label={"Job Titles"} action={"/job-title"} extra_class={"margined-side iconed-btn-lg label-md flex-fill"}/>
							<IconedButtonV icon={"chat_bubble"} hint={"Chat with the human resources"} label={"Support"} action={"/notice/indevelopment"} extra_class={"margined-side iconed-btn-lg label-md flex-fill"} />
							<IconedButtonV icon={"move_group"} hint={"Manage Leaves"} label={"Manage Leaves"} action={"/manage-leave"} extra_class={"margined-side iconed-btn-lg label-md flex-fill"} />
							
						</div>
					</div>
				</div>



				<div className="col-md-6 d-flex align-items-start justify-content-md-start">
					<div className="theme-cardify card-expand">
						<p className="theme-section-title title-nm mb-3">Salary Management</p>
						<div className="d-flex flex-wrap">

							<IconedButtonV icon={"table"} hint={"Manage Salary Grade"} label={"Salary Grade"} action={"/notice/indevelopment"} extra_class={"margined-side iconed-btn-lg label-md flex-fill"}/>
							<IconedButtonV icon={"price_change"} hint={"Assign Salary to Employee"} label={"Assign Salary"} action={"/notice/indevelopment"} extra_class={"margined-side iconed-btn-lg label-md flex-fill"}/>
							<IconedButtonV icon={"sort"} hint={"Show Amployee List and Salary"} label={"Employee Salary"} action={"/notice/indevelopment"} extra_class={"margined-side iconed-btn-lg label-md flex-fill"}/>
						
						</div>
					</div>
				</div>


			</div>



			<div className="row mt-3">
				<div className="col-md-6 d-flex align-items-start justify-content-md-start">
					<div className="theme-cardify card-expand">
						<p className="theme-section-title title-nm mb-3">Analytics And Reports Management</p>
						<div className="d-flex flex-wrap">

							<IconedButtonV icon={"co_present"} hint={"Generate Report"} label={"Generate Report"} action={"/notice/indevelopment"} extra_class={"margined-side iconed-btn-lg label-md flex-fill"}/>
							<IconedButtonV icon={"analytics"} hint={"Generate Report"} label={"Analytics"} action={"/notice/indevelopment"} extra_class={"margined-side iconed-btn-lg label-md flex-fill"}/>

						</div>
					</div>
				</div>



				<div className="col-md-6 d-flex align-items-start justify-content-md-start">
					<div className="theme-cardify card-expand">
						<p className="theme-section-title title-nm mb-3">Admin Management</p>
						<div className="d-flex flex-wrap">

							<IconedButtonV icon={"health_and_safety"} hint={"Add New Admin Account"} label={"New Admin"} action={"/notice/indevelopment"} extra_class={"margined-side iconed-btn-lg label-md flex-fill"}/>

							<IconedButtonV icon={"admin_panel_settings"} hint={"View All Admin Account"} label={"Admin Accounts"} action={"/notice/indevelopment"} extra_class={"margined-side iconed-btn-lg label-md flex-fill"}/>

							<IconedButtonV icon={"support_agent"} hint={"Contact Support at Luminous"} label={"Support"} action={"/notice/indevelopment"} extra_class={"margined-side iconed-btn-lg label-md flex-fill"}/>

							<IconedButtonV icon={"shield"} hint={"Create new role"} label={"Roles & Permissions"} action={"/manage-roles"} extra_class={"margined-side iconed-btn-lg label-md flex-fill"}/>



						</div>
					</div>
				</div>
			</div>


			<div className="row mt-3">
				<div className="col-md-6">
					<div className="theme-cardify card-expand">
							<p className="theme-section-title title-nm mb-3">Time Management</p>
							<div className="d-flex flex-wrap">

								<IconedButtonV icon={"date_range"} hint={"Manage shift time and dates"} label={"Shifts"} action={"/shift-management"} extra_class={"margined-side iconed-btn-lg label-md flex-fill"}/>

							</div>
						</div>
				</div>


				<div className="col-md-6">
					
				</div>
			</div>

		</div>
	);
}


export default MoreOptionsPanel;