import React from "react";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';


const snack_pos = {
	vertical: 'center',
	horizontal: 'right',
};
 

const SnackBarComp = ({is_snack_open, snack_status, text, close_call}) => {
	
	const {vertical, horizontal} = snack_pos;

	return (
		<Snackbar anchorOrigin={{ vertical, horizontal }} autoHideDuration={6000} open={is_snack_open} onClose={()=>close_call(false)} >
			<Alert severity={snack_status} variant="filled" sx={{ width: '150%' }} onClose={()=>close_call(false)} >
				{text}
			</Alert>
		</Snackbar>
	);
}


export default SnackBarComp;

