import React from "react";




const ProfileHeaderCardA = ({image_path, full_name, job_title, account_email})=>{
	return(
		<div className="row theme-cardify card-w-expand">
			<div className="col-md-2 d-flex align-items-center justify-content-center">
				<div id="profile_image_container">
					<img src={image_path} id="profile_image" alt="user profile pic"></img>
				</div>
			</div>	
			<div className="col-md-10 d-flex flex-column align-items-start justify-content-center">
				<p className="fw-semibold">{full_name}
					<br></br>
					<span className="fw-light">{job_title}</span>
				</p>

				<p className="fw-normal"><span className="fw-semibold">@</span>{account_email}</p>
			</div>
		</div>
	);	
}


export default ProfileHeaderCardA;