import React from 'react'

import "./stylesheets/classic_theme.css";
import LoginForm from "./components/login_form";

const EmployeeLoginPage = ()=>{
	return(

		<div className="container-fluid theme-color dark">
			<div className="row">
				<div className="col-md-12 d-flex align-items-center justify-content-center theme-full-page">
					<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN" crossOrigin="anonymous" />

					<link rel="preconnect" href="https://fonts.googleapis.com"/>
					<link rel="preconnect" href="https://fonts.gstatic.com" crossoOigin/>
					<link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,600;1,100;1,300;1,400;1,600&display=swap" rel="stylesheet"/>

				  	<link href="https://cdnjs.cloudflare.com/ajax/libs/hint.css/1.2.1/hint.min.css" rel="stylesheet"/>
					<LoginForm />
				</div>
			</div>
		</div>
	);
}



export default EmployeeLoginPage;