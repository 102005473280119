import React from "react";
import NoImagePlaceholder from "../assets/no_image.png";
import { base_url } from "../auth";




const ProfileSearchResultCard = ({profile_image, name, job_title, function_call, card_data_id})=>{

	const btnCallBack = (e)=>{
		e.preventDefault();
		function_call(card_data_id);
	}


	return(
		<div className="theme-cardify card-expand d-flex flex-column justify-content-center align-items-center mt-1">
			<div className="result_card_image_container">
				<img src={profile_image === null ? NoImagePlaceholder : base_url + profile_image} className="image_fluid" alt="profile"></img>
				
			</div>
			<div className="text-center">
				<p className="theme-text-sm fw-bold">
					{name}
					<br></br>
					<span className="theme-text-sm fw-normal">{job_title === null ? "Not Assigned" : job_title}</span>
				</p>
			</div>

			<button type="button" className="theme-btn btn-sm" onClick={(e)=>(btnCallBack(e))}>View</button>
		</div>
	);
}


export default ProfileSearchResultCard;