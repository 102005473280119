import React from "react";




const TwoButtonCard = ({title, description, btn_sec_text, btn_sec_func, btn_prim_text, btn_prim_func})=>{


	const primaryButtonCall = (e)=>{
		e.preventDefault();
		btn_prim_func();
	}


	const secondaryButtonCall = (e)=>{
		e.preventDefault();
		btn_sec_func();
	}


	return (
		<div className="theme-cardify card-md m-1 d-flex flex-column align-items-stretch flex-fill">
			<div className="row">
				<div className="col-md-12">
					<span className="theme-text-xsm">Title</span>
					<h5>{title}</h5>
				</div>
			</div>

			<div className="row mt-2 flex-grow-1">
				<div className="col-md-12 theme-text-sm flex-grow-1">
					<p>{description}</p>
				</div>
			</div>

			<div className="row mt-3">
				<div className="col-md-6">
					<button className="theme-btn btn-expand theme-btn-secondary" onClick={(e)=>(secondaryButtonCall(e))}>Delete</button>
				</div>
				<div className="col-md-6">
					<button className="theme-btn btn-expand" onClick={(e)=>(primaryButtonCall(e))}>Edit</button>
				</div>
			</div>

		</div>
	);
}


export default TwoButtonCard