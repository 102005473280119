import React, { useState, useEffect } from "react";
import { postRequest, getRequest } from "../auth";
import Swal from 'sweetalert2';
import Tooltip from '@mui/material/Tooltip';
//import { convertDateToYMD } from "../utils/utils";
import LoadingModal from "../components/loading_modal";
import SnackBarComp from "../components/snackbar";




const UserRegistrationPanel = ()=>{
	const [is_snack_open, setSnackOpen] = useState(false);
	const [reg_status_text, setRegText] = useState("");
	const [reg_status, setRegStatus] = useState("success");
	const [is_loading, setLoadingState] = useState(false); 
	const [input_errors, setInputErrors] = useState();
	const [job_titles, setJobTitles] = useState();
	const [image_input, setImageInput] = useState();



	const imageAdded = (event)=>{
		setImageInput(event.target.files[0]);
	}


	const submitRegistration = async (e, registration_uri)=>{
		e.preventDefault();
		setLoadingState(true);

		const data = {
			first_name 		: document.querySelector("#firstname").value,
			last_name 		: document.querySelector("#lastname").value,
			middle_name 	: document.querySelector("#lastname").value,
			email 			: document.querySelector("#email").value,
			birthdate 		: document.querySelector("#birthdate").value,
			contact_number 	: document.querySelector("#contact_number").value,
			civil_status	: document.querySelector("#civil_status").value,
			age 			: document.querySelector("#age").value,
			salary 			: document.querySelector("#salary").value,
			job_title 		: document.querySelector("#job_title").value,
			profile_image 	: image_input,
			gender 			: document.querySelector("#gender").value,
		}
		

		await postRequest(registration_uri, data).then(response=>{
			setLoadingState(false);
			if(response.status === 201){
				setRegStatus("success");
				setRegText("Registration Completed")
				setSnackOpen(true);
			} else {
				setRegStatus("warning");
				setRegText("Data was not saved")
				setSnackOpen(true);
			}
		}).catch(error=>{
			if(error.response.status === 409){
				setLoadingState(false);
				setRegStatus("warning");
				setRegText(error.response.data.response);
				setSnackOpen(true);
			} else if (error.response.status === 403){
				setLoadingState(false);
				setRegStatus("warning");
				setRegText("Access was denied");
				setSnackOpen(true);
			} else{
				setLoadingState(false);
				setRegStatus("error");
				setRegText("Error submitting form");
				setSnackOpen(true);
				setInputErrors(error.response.data.error);
			}
		});
	}


	const getJobTitles = async()=>{
		await getRequest("/job-titles").then(response=>{
			if(response.status === 200){
				setJobTitles(response.data);
			}
		}).catch(error=>{
			Swal.fire({
				icon: "error",
				title: "Error",
				text: "Error occured while retrieving job titles data: " + error,
			});
		});
	}


	const clearFormAlert = (e)=>{
		e.preventDefault();
		Swal.fire({
		  title: "Clear Form?",
		  showCancelButton: true,
		  confirmButtonText: "Clear",
		}).then((result) => {
		  if (result.isConfirmed) {
		    document.querySelector("#registration-form").reset();
		  } 
		});
	}


	useEffect(()=>{
		getJobTitles();
	}, []);


	return(
		<div className="container-fluid">
			<LoadingModal is_loading={is_loading} />
			<SnackBarComp is_snack_open={is_snack_open} snack_status={reg_status} text={reg_status_text} close_call={setSnackOpen}/>

			<form id="registration-form">
				<div className="row">
					<div className="col-md-6">
						<label className="form-label">Profile Image</label>
						<input type="file" accept="image/jpg image/jpeg image/png" id="profile_image" onChange={imageAdded}></input>
					</div>
				</div>

				<div className="row mt-3">
					<div className="col-md-6">
						<div class="mb-3">
							<label class="theme-label-sm">First Name</label>
							<input type="text" class="form-control theme-input-field" id="firstname" name="firstname" placeholder="John" required/>
							{
								input_errors && input_errors["first_name"] &&
								<p className="theme-color warning theme-label-sm">{input_errors["first_name"]}</p>
							}
						</div>

						<div class="mb-3">
							<label class="theme-label-sm">Last Name</label>
							<input type="text" class="form-control theme-input-field" id="lastname" name="lastname" placeholder="Doe" required/>
							{
								input_errors && input_errors["last_name"] &&
								<p className="theme-color warning theme-label-sm">{input_errors["last_name"]}</p>
							}
						</div>

						<div class="mb-3">
						  <label class="theme-label-sm">Middle Name</label>
						  <input type="text" class="form-control theme-input-field" id="middlename" name="middlename" placeholder="Dillan" required/>
						  	{
								input_errors && input_errors["middle_name"] &&
								<p className="theme-color warning theme-label-sm">{input_errors["middle_name"]}</p>
							}
						</div>

						<div class="mb-3">
						  <label class="theme-label-sm">Email Address</label>
						  <input type="email" class="form-control theme-input-field" id="email" name="email" required/>
						  	{
								input_errors && input_errors["email"] &&
								<p className="theme-color warning theme-label-sm">{input_errors["email"]}</p>
							}
						</div>

						<div className="mb-3">
							<label className="form-label">Select Gender</label>
							<select className="form-select" id="gender" name="gender">
								<option disabled>Select Gender</option>
								<option value="male">Male</option>
								<option value="female">Female</option>
							</select>
						</div>


					</div>


					<div className="col-md-6">
						<div class="mb-3">
						  <label class="theme-label-sm">Age</label>
						  <input type="number" class="form-control theme-input-field" id="age" name="age" required/>
						  	{
								input_errors && input_errors["age"] &&
								<p className="theme-color warning theme-label-sm">{input_errors["age"]}</p>
							}
						</div>

						<div class="mb-3">
						  <label class="theme-label-sm">Birthdate</label>
						  <input type="date" class="form-control theme-input-field" id="birthdate" name="birthdate" required/>
						  {
								input_errors && input_errors["birthdate"] &&
								<p className="theme-color warning theme-label-sm">{input_errors["birthdate"]}</p>
							}
						</div>

						<div class="mb-3">
						  <label class="theme-label-sm">Contact Number</label>
						  <input type="number" class="form-control theme-input-field" id="contact_number" name="contact_number" required/>
						  	{
								input_errors && input_errors["contact_number"] &&
								<p className="theme-color warning theme-label-sm">{input_errors["contact_number"]}</p>
							}
						</div>


						<div class="mb-3">
							<label class="theme-label-sm">Civil Status</label>
							<select class="form-select theme-input-field" id="civil_status" aria-label="Default select example" required>
								<option selected disabled>Select Civil Status</option>
								<option value="single">Single</option>
								<option value="married">Married</option>
								<option value="divorced">Divorced</option>
								<option value="widowed">Widowed</option>
							</select>
							{
								input_errors && input_errors["civil_status"] &&
								<p className="theme-color warning theme-label-sm">{input_errors["civil_status"]}</p>
							}
						</div>
					</div>
				</div>


				<div className="row mt-3">
					<div className="col-md-6">
						<label className="form-label">Job Title</label>
						<select name="job_title" className="form-select" id="job_title">
							<option disabled>Select Job Title</option>
							{
								job_titles && Object.keys(job_titles).map(key=>{
									return <option value={job_titles[key]["id"]}>{job_titles[key]["job_title"]}</option>
								})
								
							}
						</select>
					</div>
					<div className="col-md-6">
						<label className="form-label">Salary</label>
						<input type="number" className="form-control" id="salary"></input>
					</div>
				</div>


				<div className="row mt-4">
					<div className="col-md-12 d-flex flex-row justify-content-end">
						<button className="theme-btn btn-md theme-btn-warning" onClick={(e)=>clearFormAlert(e)}>Clear Form</button>
						
						<Tooltip title="Automatically create account on registration. Automatically generated password and email will be shown after successful registration.">
							<button className="theme-btn btn-md " onClick={(e)=>submitRegistration(e, "/autoregister/")}>Submit & Generate Account
							</button>
						</Tooltip>
						
						<button className="theme-btn btn-md" onClick={(e)=>submitRegistration(e, "/register/user/")}>Submit Registration</button>
					</div>
				</div>

			</form>
		</div>
	);
}


export default UserRegistrationPanel;