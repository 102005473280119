import React, { useState } from "react";
import { postRequest, getRequest } from "../auth";
import Swal from 'sweetalert2';


const DebugPanel = ()=>{
	const [uri, setUri] = useState("");

	const testAPIGet = async(e)=>{
		e.preventDefault();
		await getRequest(uri).then(response=>{
			Swal.fire({
			  icon: "success",
			  title: "API call complete",
			  text: uri + " with status: " + response.status,
			});
		}).catch(error=>{
			Swal.fire({
			  icon: "error",
			  title: "Registration Error",
			  text: "Error: + " + error,
			});
		})
	}

	const testAPIPost = async(e)=>{
		e.preventDefault();
		await postRequest(uri).then(response=>{
			Swal.fire({
			  icon: "success",
			  title: "API call complete",
			  text: uri + " with status: " + response.status,
			});
		}).catch(error=>{
			Swal.fire({
			  icon: "error",
			  title: "Registration Error",
			  text: "Error: + " + error,
			});
		})
	}



	return (
		<>
		<label className="form-label">Enter URI</label>
		<input className="form-control" type="text" onChange={(e)=>(setUri(e.target.value))} />

		<button class="theme-btn btn-md" onClick={(e)=>(testAPIGet(e))}>GET REQUEST</button>
		<button class="theme-btn btn-md" onClick={(e)=>(testAPIPost(e))}>POST REQUEST</button>
		</>
	);
}


export default DebugPanel;