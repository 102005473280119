import React from "react";


const RequestCard = ({title, description, sub_title, btn_prim_func, btn_sec_func})=>{

	const primaryButtonCall = (e)=>{
		e.preventDefault();
		btn_prim_func.callable();
	}


	const secondaryButtonCall = (e)=>{
		e.preventDefault();
		btn_sec_func.callable();
	}


	return (
		<div className="theme-cardify card-md m-1 d-flex flex-column align-items-stretch flex-fill">
			<div className="row">
				<div className="col-md-12">
					<span className="theme-text-xsm">Title</span>
					<h5>{title}</h5>
					<span className="theme-text-sm">{sub_title}</span>
				</div>
			</div>

			<div className="row mt-2 flex-grow-1">
				<div className="col-md-12 theme-text-nm flex-grow-1">
					<p>{description}</p>
				</div>
			</div>

			<div className="row mt-3">
				<div className="col-md-6">
					{
						btn_sec_func.callable !== null ? <button className="theme-btn btn-expand theme-btn-secondary" onClick={(e)=>(secondaryButtonCall(e))}>{btn_sec_func.btn_label}</button> : ""
					}
				</div>
				<div className="col-md-6">
					{
						btn_prim_func.callable !== null ? <button className="theme-btn btn-expand" onClick={(e)=>(primaryButtonCall(e))}>{btn_prim_func.btn_label}</button> : ""
					}
				</div>
			</div>

		</div>
	);
}




export default RequestCard;