import React, { useState, useEffect } from "react";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Swal from 'sweetalert2';
import { postRequest, getRequest } from "../auth";
import { useHRISContext } from "../context";
import { serializeServerDateTimeDate } from "../utils/utils";
import SmallCard from "../components/small_card";
import RequestCard from "../components/request_card";




// Main Panel Component
const RequestLeavePanel = ()=>{
	const { user_id } = useHRISContext();
	const [leave_types, setLeaveTypes] = useState();
	const [leave_requests, setLeaveRequests] = useState();
 
	const retrieveLeaveCategories = async()=>{
		await getRequest("/leave/type").then(response=>{
			if(response.status === 200){
				setLeaveTypes(response.data);
			}
		}).catch(error=>{
			Swal.fire({
				icon: "error",
				title: "Error",
				text: "Error: " + error,
			});
		})
	}


	const retrieveLeaveRequests = async()=>{
		await getRequest("/leave/request/?user_account_id=" + user_id).then(response=>{
			if(response.status === 200){
				setLeaveRequests(response.data);
			}
		}).catch(error=>{
			Swal.fire({
				icon: "error",
				title: "Error",
				text: "Error: " + error,
			});
		});
	}


	const requestNewLeave = async(e)=>{
		e.preventDefault();

		const data = {
			user_account_id: user_id,
			target_leave_date: document.querySelector("#apply_date_field").value,
			leave_header: document.querySelector("#leave_heading_input").value,
			reason: document.querySelector("#leave_body_input").value,
			leave_type_id: document.querySelector("#leave_type_input").value,
			status: false,
		}

		await postRequest("/leave/request/", data).then(response=>{
			if(response.status === 201){
				Swal.fire({
					icon: "success",
					title: "Request Complete",
					text: "New leave request was submitted",
				});

				retrieveLeaveRequests();
			}
		}).catch(error=>{
			Swal.fire({
				icon: "error",
				title: "Error",
				text: "Error: " + error,
			});
		});
	}


	/**
	const deleteRequest = ()=>{
		alert("delete called");
	}
	**/

	const updateRequest = ()=>{
		alert("update called");
	}


	useEffect(()=>{
		retrieveLeaveCategories();

		const initLeaveRequests = async()=>{
			await getRequest("/leave/request/?user_account_id=" + user_id).then(response=>{
				if(response.status === 200){
					setLeaveRequests(response.data);
				}
			}).catch(error=>{
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Error: " + error,
				});
			});
		}
		
		initLeaveRequests();
	}, [user_id]);
	

	return(
		<div className="container-fluid">
			<div className="row">
				<div className="col-md-6 d-flex justify-content-start">
					<p className="theme-section-title title-md">Leave Management</p>
				</div>

				<div className="col-md-6 d-flex justify-content-end">
				</div>
			</div>

			<div className="row mt-5">
				<div className="col-md-9">
					<div className="theme-cardify card-w-expand">
						<div className="row">
							<div className="col-md-4">
								<h5>Apply New Leave</h5>
							</div>
							<div className="col-md-4">
								<div class="input-group mb-3 mt-2">
									<span class="input-group-text">Date</span>
									<input type="date" class="form-control"  id="apply_date_field" aria-label="Date" aria-describedby="apply_date_field"></input>
								</div>
							</div>
							<div className="col-md-4">
								<div class="input-group mb-3 mt-2">
									<span class="input-group-text" id="apply_date_field">Type</span>
									<select className="form-select" name="leave_type_input" id="leave_type_input">
										<option disabled>Select From Category</option>
										{
											leave_types && Object.keys(leave_types).map(key=>{
												return <option value={leave_types[key]["id"]}>{leave_types[key]["leave_type"]}</option>
											})
										}
									</select>
								</div>
							</div>
						</div>
						
						<div className="row">
							<div className="col-md-12">
								<label className="form-label">Title</label>
								<input type="text" className="form-control" id="leave_heading_input"></input>

								<textarea className="form-control mt-2" id="leave_body_input" placeholder="leave message"></textarea>
							</div>
						</div>
						
						<div className="d-flex justify-content-end mt-3">
							<button className="theme-btn btn-md" onClick={(e)=>(requestNewLeave(e))}>Submit</button>
						</div>
					</div>



					<div className="requests d-flex flex-wrap">
						{
							leave_requests && Object.keys(leave_requests).map(key=>{
								const leave_subtitle = "Tyep: " + leave_requests[key]["leave_type_name"] + "\n Requestd on: " + serializeServerDateTimeDate(leave_requests[key]["created_at"]) + " for date: " + serializeServerDateTimeDate(leave_requests[key]["target_leave_date"]);

								return <RequestCard
									title={leave_requests[key]["leave_header"]} 
									description={leave_requests[key]["leave_header"]} 
									sub_title={leave_subtitle} 
									btn_prim_func={{callable: updateRequest, btn_label: "Update"}} 
									btn_sec_func={{callable: null, btn_label: "Cancel"}} />
							})
						}
					</div>
				</div>

				

				<div className="col-md-3 border-start">
					<SmallCard title={"Remaining Leaves"} body={ "7 Leaves" }/>
					<SmallCard title={"Leaves Taken"} body={ "7 Leaves" }/>
					<Calendar className="mt-2" />
				</div>
			</div>
		</div>
	);
}


export default RequestLeavePanel;