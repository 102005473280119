import React, { createContext, useContext, useState } from 'react';


const HRISContext = createContext();

export const useHRISContext = () => {
  return useContext(HRISContext);
};


export const HRISProvider = ({ children }) => {
	const [is_authenticated, setIsAuthenticated] = useState(false);
	const [user_id, setUserId] = useState();
	const [user_name, setUserName] = useState();
	const [is_admin, setAdminStatus] = useState();

	const setId = (id)=>{
		setUserId(id);
	}


	const value = {
		user_id,
		user_name,
		is_admin,
		is_authenticated,

		setId,
		setUserName,
		setAdminStatus,
		setIsAuthenticated,
	};


	return (
		<HRISContext.Provider value={value}>
		  {children}
		</HRISContext.Provider>
	);
};

