import React, { useState, useEffect } from "react";
import Backdrop from '@mui/material/Backdrop';
import { postRequest, getRequest } from "../auth";
import Swal from 'sweetalert2';





 const JobTitleCards = ({title, description})=>{
 	return (
 		<div className="theme-cardify card-md m-1 d-flex flex-column align-items-stretch flex-fill">
 			<div className="row">
 				<div className="col-md-12">
 					<span className="theme-text-xsm">Title</span>
 					<h5>{title}</h5>
 				</div>
 			</div>

 			<div className="row mt-2 flex-grow-1">
 				<div className="col-md-12 theme-text-sm flex-grow-1">
 					<p>{description}</p>
 				</div>
 			</div>

 			<div className="row mt-3">
 				<div className="col-md-6">
 					<button className="theme-btn btn-expand theme-btn-secondary">Delete</button>
 				</div>
 				<div className="col-md-6">
 					<button className="theme-btn btn-expand">Edit</button>
 				</div>
 			</div>

 		</div>
 	);
 }




const JobTitlePanel = ()=>{
	const [is_new_job_modal_open, setNewJobModalOpen] = useState(false);
	const [job_titles, setJobTitle] = useState();




	const retrieveJobTitles = async()=>{
		await getRequest("/job-titles").then(response=>{
			if(response.status === 200){
				setJobTitle(response.data);
			}
		}).catch(error=>{
			Swal.fire({
				icon: "error",
				title: "Error",
				text: "Error: " + error,
			});
		});
	}


	const submitNewJobTitle = async(e)=>{
		e.preventDefault();
		setNewJobModalOpen(false);

		const data = {
			job_title : document.querySelector("#job_title").value,
			job_description : document.querySelector("#job_description").value,
			base_salary : document.querySelector("#base_salary").value,
			max_salary : document.querySelector("#max_salary").value,
		};

		await postRequest("/job-titles/", data).then(response=>{
			if(response.status === 201){
				Swal.fire({
					icon: "success",
					title: "Completed",
					text: "New job title was created",
				});
			}
			retrieveJobTitles();
		}).catch(error=>{
			Swal.fire({
				icon: "error",
				title: "Error",
				text: "Error: " + error,
			});
		});
	}


	useEffect(()=>{
		retrieveJobTitles();
	}, []);


	return(
		<div className="container-fluid">
			<div className="row">
				<div className="col-md-6">
					<p className="theme-section-title title-md">Job Titles</p>
				</div>
				<div className="col-md-6 d-flex align-items-end justify-content-end">
					<button className="theme-btn btn-md" onClick={(e)=>(setNewJobModalOpen(true))}>Add Job Title</button>
				</div>
			</div>

			<div className="row mt-4">
				<div className="col-md-12 border-bottom d-flex align-items-end justify-content-end p-4">
					<h3>Title Count: { job_titles && Object.keys(job_titles).length}</h3>
				</div>
			</div>

			<div className="row mt-4">
				<div className="col-md-12 d-flex flex-wrap">
					{		
						job_titles ?  Object.keys(job_titles).map(key=>{
							return <JobTitleCards title={job_titles[key]["job_title"]} description={job_titles[key]["job_description"]}/>
								
						}) : "Loding..."
					}
					
				</div>
			</div>



			





			{/**  MODALS  **/}
			<Backdrop
				sx={{ color: '#fff', backgroundColor: 'rgba(41, 2, 64, 0.5)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={is_new_job_modal_open}
			>
				
				<form>
				<div className="container theme-modal-container text-black p-3 theme-modal-md">
					<div className="row theme-modal-header">
						<div className="col-md-6">
							<p className="theme-section-title title-nm">New Job Title</p>
						</div>
						<div className="col-md-6 d-flex align-items-end justify-content-end">
							<button type="button" onClick={(e)=>setNewJobModalOpen(false)} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
					</div>

					<div className="row">
						<div className="col-md-12">
							<label className="form-label">Job Title</label>
							<input type="text" className="form-control" placeholder="Manager" id="job_title" required></input>

							<label className="form-label">Job Description</label>
							<textarea className="form-control" id="job_description" required></textarea>
						</div>
					</div>

					<div className="row mt-2">
						<label className="form-label">Salary Range</label>
						<div className="col-md-6">
							<div class="input-group mb-3">
							  <span class="input-group-text" id="base_salary_span">Base</span>
							  <input type="number" class="form-control" id="base_salary" placeholder="20000" aria-label="base_salary" aria-describedby="base_salary_span" required>
							  </input>
							</div>
						</div>
						<div className="col-md-6">
							<div class="input-group mb-3">
							  <span class="input-group-text" id="max_salary_span">Maximum</span>
							  <input type="number" class="form-control" id="max_salary" placeholder="40000" aria-label="max_salary" aria-describedby="max_salary_span" required>
							  </input>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-md-12 theme-modal-footer">
							<button className="theme-btn btn-xsm" onClick={(e)=>(submitNewJobTitle(e))}>Create</button>
						</div>
					</div>
				</div>
				</form>
			</Backdrop>
		</div>
	);
}


export default JobTitlePanel;