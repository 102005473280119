import React, { useState, useEffect } from "react";
import { getRequest } from "../../auth";
import ProfileHeaderCardA from "../../components/profile_components/profile_header_cardA";
import PersonalInfoCardA from "../../components/profile_components/personal_info_cardA";



const UserProfileView = ({data_id})=>{
	const [user_account_data, setUSerAccountData] = useState();
	const [user_registers_data, setUserRegisterData] = useState();



	


	useEffect(()=>{
		console.log("Called effect")
		const getUserAccount = async()=>{
			await getRequest("/register/account?user=" + data_id).then(response=>{
				if(response.status === 200){
					setUSerAccountData(response.data);
				}
			}).catch(error=>{
				alert("error: " + error.status);
			})
		}


		const getUserRegisterdata = async()=>{
			await getRequest("/register/user/" + data_id).then(response=>{
				if(response.status === 200){
					setUserRegisterData(response.data);
				}
			}).catch(error=>{
				alert("error: " + error.status);
			})
		}
			
		getUserAccount();
		getUserRegisterdata();
	}, [data_id]);



	return (
		<div className="container-fluid">
			<ProfileHeaderCardA 
				image_path={user_registers_data && user_registers_data["profile_image"]} 
				full_name={user_registers_data && user_registers_data["first_name"] + " " + user_registers_data["middle_name"] + ", " + user_registers_data["last_name"]} 
				job_title={user_registers_data && user_registers_data["job_title_name"]} 
				account_email={user_account_data && user_account_data["email"]} 
			/>


			<div className="row mt-2">
				<div className="col-md-8">
					<PersonalInfoCardA 
						birthdate={user_registers_data && user_registers_data["birthdate"]}
						age={user_registers_data && user_registers_data["age"]}
						civil_status={user_registers_data && user_registers_data["civil_status"]}
						gender={user_registers_data && user_registers_data["gender"]}
						contact_number={user_registers_data && user_registers_data["contact_number"]}
						email={user_registers_data && user_registers_data["email"]}
					/>
				</div>

				<div className="col-md-4">
					<div className="row theme-cardify card-expand">
						<div className="col-md-12">
							<h5>Account Information</h5>
							<div className="pt-1 pb-1">
								<span className="fw-light">Email: </span>
								<br></br>
								<p className="fw-semibold">{user_account_data && user_account_data["email"]}</p>
							</div>

							<table className="table theme-text-xsm">
								<thead>
									<tr>
										<th>Active</th>
										<th>Admin</th>
										<th>Staff</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											{user_account_data && user_account_data["is_active"] === true ? 
											<span class="material-symbols-outlined theme-text-xsm pl-3 theme-color success">check_circle</span>	
											: 
											<span class="material-symbols-outlined theme-text-xsm theme-color warning">cancel</span>}
										</td>
										<td>
											{user_account_data && user_account_data["is_admin"] === true ? 
											<span class="material-symbols-outlined theme-text-xsm pl-3 theme-color success">check_circle</span>	
											: 
											<span class="material-symbols-outlined theme-text-xsm theme-color warning">cancel</span>}
										</td>
										<td>
											{user_account_data && user_account_data["is_staff"] === true ? 
											<span class="material-symbols-outlined theme-text-xsm pl-3 theme-color success">check_circle</span>	
											: 
											<span class="material-symbols-outlined theme-text-xsm theme-color warning">cancel</span>}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}



export default UserProfileView;