import React, { useEffect, useState } from "react";
import { getRequest } from "../auth"; 
import Swal from 'sweetalert2';
import Backdrop from '@mui/material/Backdrop';
import UserRegistrationPanel from "./user_registration_panel";





const EmployeeList = ()=>{
	const [users, setUsers] = useState();
	const [is_profile_modal_open, setProfileModalOpen] = useState(false);
	const [inspecting_profile_index, setInspectingProfileIndex] = useState();

	// Modal states 
	const [is_registration_modal_open, setRegistrationModalOpen] = useState();


	useEffect(()=>{

		const requestEmployeeList = async()=>{
			await getRequest("/register/user/")
			.then(response=>{
				if(response.status === 200){
					setUsers(response.data)
				}
			}).catch(error=>{
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Error occured while retrieving Employee list data" + error,
				});
			});
		};

		requestEmployeeList();
	}, []);



	const openInformation = (data_id)=>{
		setInspectingProfileIndex(data_id);
		setProfileModalOpen(true);
	}



	const displayProfile = (data_id)=>{
		//
		return <div className="container-fluid">
			<div className="">
				<div className="col-md-12 mt-3">
					<div className="table-container overflow-x-auto">
						<table className="table table-bordered">
							<tbody>
								<tr>
									<td>Last Name: </td>
									<td>{users[data_id]["last_name"]}</td>
								</tr>
								<tr>
									<td>First Name: </td>
									<td>{users[data_id]["first_name"]}</td>
								</tr>
								<tr>
									<td>Middle Name: </td>
									<td>{users[data_id]["middle_name"]}</td>
								</tr>
								<tr>
									<td>Email: </td>
									<td>{users[data_id]["email"]}</td>
								</tr>
								<tr>
									<td>Mobile Number: </td>
									<td>{users[data_id]["contact_number"]}</td>
								</tr>
								<tr>
									<td>birthdate: </td>
									<td>{users[data_id]["birthdate"]}</td>
								</tr>
								<tr>
									<td>age: </td>
									<td>{users[data_id]["age"]}</td>
								</tr>
								<tr>
									<td>civil_status: </td>
									<td>{users[data_id]["civil_status"]}</td>
								</tr>
								
							</tbody>
						</table>
					</div>
				</div>
			</div>

		</div>
	}





	return (
		<div className="container-fluid">

			<div className="row mt-3">
				<div className="col-md-6">
					<p className="theme-section-title title-md">Employee List</p>
				</div>

				<div className="col-md-6 d-flex justify-content-end">
					<button className="theme-btn btn-sm" onClick={(e)=>(setRegistrationModalOpen(true))}>Create New</button>
				</div>
			</div>


			<div className="row border-top border-bottom mt-3">
				<div className="col-md-12 pt-5 pb-5">
					
				</div>
			</div>


			<div className="row mt-5">
				<div className="col-md-12">

					<div className="table-container overflow-x-auto">
						<table className="table table-hover table-bordered">
							<thead>
								<tr>
									<th>name</th>
									<th>email</th>
								</tr>
							</thead>

							<tbody>
								{!users && <h5>Loading Data...</h5>}
								{users && Object.keys(users).map(key=>{

									return <tr onClick={(e)=>openInformation(key)}>
										<td>{users[key]["last_name"]}, {users[key]["first_name"]} {users[key]["middle_name"]}</td>
										<td>{users[key]["email"]}</td>
									</tr>

								})}
							</tbody>
						</table>

					</div>
					

					{/** User Info Modal **/}
					<Backdrop sx={{ color: '#fff', backgroundColor: 'rgba(41, 2, 64, 0.5)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
						open={is_profile_modal_open}
					>
						<div className="container theme-modal-container text-black p-3 theme-modal-md">
							<div className="row theme-modal-header">
								<div className="col-md-6">
									<p className="theme-section-title title-nm">mployee Profile</p>
								</div>
								<div className="col-md-6 d-flex align-items-end justify-content-end">
									<button type="button" onClick={(e)=>setProfileModalOpen(false)} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
								</div>
							</div>
							
							<div className="modal-body">
								<div className="row">
									<div className="col-md-12">
										{users && inspecting_profile_index && displayProfile(inspecting_profile_index)}
									</div>
								</div>
							</div>
							
							<div className="d-flex justify-content-end">
								<button className="theme-btn btn-sm ml-5">View Profile</button>
							</div>
						</div>
					</Backdrop>



					{/** REgister New Emplooyee Modal **/}
					<Backdrop
					sx={{ color: '#fff', backgroundColor: 'rgba(41, 2, 64, 0.5)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={is_registration_modal_open}
					>
						<div className="container theme-modal-container text-black p-3 theme-modal-md">
							<div class="row theme-modal-header">
								<div className="col-md-6">
									<p className="theme-section-title title-nm">Register New Employee</p>
								</div>
								<div className="col-md-6 d-flex align-items-end justify-content-end">
									<button type="button" onClick={(e)=>setRegistrationModalOpen(false)} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
								</div>
							</div>
							<div class="row theme-modal-body theme-scroll-bar">
								<UserRegistrationPanel />
							</div>
						</div>
					</Backdrop>


				</div>
			</div>
		</div>
	);
}



export default EmployeeList;