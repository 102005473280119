import React, { useEffect, useState } from "react";
import { postRequest, getRequest } from "../auth";
import Swal from 'sweetalert2';
import SmallCard from "../components/small_card";
import TwoButtonCard from "../components/two_btn_card";


const ManageLeavePanel = ()=>{
	const [leave_types, setLeaveTypesCategories] = useState();



	const retrieveLeaveCategories = async()=>{
		await getRequest("/leave/type").then(response=>{
			if(response.status === 200){
				setLeaveTypesCategories(response.data);
			}
		}).catch(error=>{
			Swal.fire({
				icon: "error",
				title: "Error",
				text: "Error: " + error,
			});
		})
	}



	const submitNewCategory = async(e) => {
		e.preventDefault();

		const data = {
			leave_type: document.querySelector("#leave_type_name").value,
			leave_description: document.querySelector("#leave_type_description").value 
		}
		await postRequest("/leave/type/", data).then(response=>{
			if(response.status === 201){
				Swal.fire({
					icon: "success",
					title: "Complete",
					text: "New leave category added",
				});
				retrieveLeaveCategories();
			}
		}).catch(error=>{
			Swal.fire({
				icon: "error",
				title: "Error",
				text: "Error: " + error,
			});
		});
	}

	


	const editLeaveType = ()=>{
		alert("Edit called");
	}

	const deleteLeaveType = ()=>{
		alert("delete called");
	}


	useEffect(()=>{
		retrieveLeaveCategories();
	}, []);


	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-md-12">
					<p className="theme-section-title title-md">Leave Types Management</p>
				</div>
			</div>

			<div className="row mt-4">
				<div className="col-md-9">
					<div className="theme-cardify card-w-expand">
						<div className="row">
							<div className="col-md-12">
								<h5>Create Leave Category</h5>
							</div>
						</div>
						
						<div className="row mt-2">
							<div className="col-md-12">
								<label className="form-label theme-text-sm">Category name</label>
								<input type="text" className="form-control" id="leave_type_name"></input>

								<textarea className="form-control mt-2" id="leave_type_description" placeholder="Leave type description"></textarea>
							</div>
						</div>
						
						<div className="d-flex justify-content-end mt-3">
							<button className="theme-btn btn-md" onClick={(e)=>{submitNewCategory(e)}}>Submit</button>
						</div>
					</div>


					<div className="mt-4 row">
						<p className="theme-section-title title-sm">Categories</p>
						<div className="col-md-12 d-flex flex-wrap">
							{
								leave_types && Object.keys(leave_types).map(key=>{
									return <TwoButtonCard title={leave_types[key]["leave_type"]} description={leave_types[key]["leave_description"]} btn_sec_text={"Delete"} btn_sec_func={editLeaveType} btn_prim_text={"Edit"} btn_prim_func={deleteLeaveType} />
								})
							}
						</div>
					</div>
				</div>




				<div className="col-md-3 border-start">
					<SmallCard title={"Total Categories"} body={leave_types && Object.keys(leave_types).length} />
				</div>
			</div>
		</div>
	);
}


export default ManageLeavePanel;