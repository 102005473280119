import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { motion } from "framer-motion"
import { useAuthVerify } from "./auth";


import Header from './components/header';
import SideBar from './components/sidebar';


import "./stylesheets/classic_theme.css";
import "./stylesheets/content_panel.css";
import "./stylesheets/sidebar.css";



const panel_variants = {
	full:{
		marginLeft: 0,
	},
	adjusted:{
		marginLeft:"80px",
	},
}

const sidebar_variants = {
	open:{
		width: "80px",
		x:0,
		display: "block"
	},
	hidden:{
		width: "0px",
		display: "block",
		x:-90
	},
}





const Root = ()=>{
	const location = useLocation();
  	const currentPath = location.pathname;
  	const [in_login, setInLogin] = useState(true);

  	const [sidebar_open, setSidebarOpen] = useState(false);

	useAuthVerify();

	const toggleSidebar = ()=>{
		console.log("Cliked")
		if (sidebar_open){
			setSidebarOpen(false)
		}else if(!sidebar_open){
			setSidebarOpen(true)
		}

	}


	useEffect(()=>{
		if(currentPath === "/login"){
			setSidebarOpen(false);
			setInLogin(true);
		} else {
			setInLogin(false);
		}
	}, [currentPath]);



	return(
		<div className="Root">
			
			<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN" crossOrigin="true" />
			<link rel="preconnect" href="https://fonts.googleapis.com"/>
			<link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true"/>
			<link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,600;1,100;1,300;1,400;1,600&display=swap" rel="stylesheet"/>
		  	<link href="https://cdnjs.cloudflare.com/ajax/libs/hint.css/1.2.1/hint.min.css" rel="stylesheet"/>
		  	<script src="https://cdn.jsdelivr.net/npm/sweetalert2@11"></script>
		  	




		  	<motion.div
		  	initial="open"
		  	transition={{ duration: 0.2 }}
		  	animate={sidebar_open ? "open":"hidden"}
		  	variants={sidebar_variants}
		  	>
		  		{!in_login && <SideBar />}
		  	</motion.div>
	      	

	      	{!in_login && <Header toggleSidebar={toggleSidebar} value={sidebar_open}/>}

		  	<motion.div 
		  	className={!in_login && "panel"}
		  	initial="adjusted"
		  	transition={{ duration: 0.2 }}
		  	animate={sidebar_open ? "adjusted":"full"}
		  	variants={panel_variants}
		  	>

				<div className={!in_login && "subpanel"}>
					<Outlet />
				</div>

		  	</motion.div>
	

		</div>
	);
}


export default Root;



/**
<motion.div className={!in_login && "panel"}>
				<div className={!in_login && "subpanel"}>
					<Outlet />
				</div>		  		
		  	</motion.div>
**/