import React from 'react';



import "../stylesheets/classic_theme.css";


const MaterialIcon = ({icon_name, extra_class})=>{
	return(
		<span class={"material-symbols-outlined material-icons theme-icon-lightened" + extra_class}>
			<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0" />
			{icon_name}
		</span>
	);	
}


export default MaterialIcon;