import React from 'react';

import "../stylesheets/classic_theme.css";
import InDevelopmentIllustration from "../assets/in_development_illustration.svg";



const InDevelopmentPanel = ()=>{

	return(
		<div className="InDevelopmentPanel container-fluid">
			<div className="row">
				<div className="col-md-12 d-flex flex-wrap align-items-center justify-content-center theme-full-height">
					<img src={InDevelopmentIllustration} className="theme-indevelopment-illustration" alt="development illustration"></img>
					<p className="theme-indevelopment-text">In Development</p>
				</div>
			</div>
		</div>
	);
}


export default InDevelopmentPanel;