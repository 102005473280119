


const convertDateToYMD = (inputDate)=>{
  var dateArray = inputDate.split('-');
  var outputDate = dateArray[0] + '-' + dateArray[1] + '-' + dateArray[2];
  return outputDate;
}


const formatTime = (inputTime)=>{
  const parsedTime = new Date(`2000-01-01T${inputTime}Z`);
  const formattedTime = new Intl.DateTimeFormat('fil-PH', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone: 'UTC'
  }).format(parsedTime);

  return formattedTime;
}



// 2023-12-09T04:09:55.212312Z
const serializeServerDateTimeDate = (date_time)=>{
  return date_time.split("T")[0];
}

const serializeServerDateTimeTime = (date_time)=>{
  return date_time.split("T")[1];
}



export { convertDateToYMD, formatTime, serializeServerDateTimeDate, serializeServerDateTimeTime};


