import React, { useState, useEffect } from "react";
import { getRequest } from "../auth";
import Swal from 'sweetalert2';
import MaterialIcon from "../components/material_icon";




const ModulePermissionCard = ({icon, module_name})=>{
	return <div className="theme-cardify card-md mt-1">
		<div className="row">
			<div className="col-md-12 d-flex align-items-center justify-content-start">
				<MaterialIcon icon_name={icon}/><span className="pl-4">{module_name} Module</span>
			</div>
		</div>
	</div>
}





const RoleManagementPanel = ()=>{
	const [permissions_list, setPermissionList] = useState();
	const [selected_permissions, setSelectedPermissions] = useState([]);


	const retrievePermissionsList = async()=>{
		await getRequest("permissions/list").then(response=>{
			if(response.status === 200){
				setPermissionList(response.data);
			}
		}).catch(error=>{
			Swal.fire({
				icon: "error",
				title: "Error",
				text: "Error: " + error,
			});
		});
	}


	const addPermissionToSelected = (e)=>{
		e.preventDefault();

		const selections = selected_permissions
		selections.push(document.querySelector("#permission_select_form").value);

		setSelectedPermissions(selections);

		console.log(selected_permissions);
	}


	useEffect(()=>{
		retrievePermissionsList();
	}, []);


	return (
		<div className="container-fluid">


			<div className="row">
				<div className="col-md-12">
					<p className="theme-section-title title-md">Role & Permission Management</p>
				</div>
			</div>

			<div className="row mt-3">
				<div className="col-md-8">
					<div className="theme-cardify card-w-expand">
						<p className="theme-section-title title-md">New Role</p>

						<label className="form-label">Role Name</label>
						<input type="text" className="form-control" id="role_name"></input>

						<div className="row">
							<div className="col-md-8">
								<select className="form-select" id="permission_select_form">
									<option value="">Select Permission</option>
									{
										permissions_list && Object.keys(permissions_list).map(key=>{
											return <option value={permissions_list[key]["codename"]}>{permissions_list[key]["name"]}</option>
										})
									}
								</select>
							</div>
							<div className="col-md-4">
								<button className="theme-btn btn-expand" onClick={(e)=>(addPermissionToSelected(e))}>Add To Group</button>
							</div>
						</div>

						<table className="table mt-3 theme-bg">
							<thead>
								<tr>
									<th>Permission Selected</th>
								</tr>
							</thead>

							<tbody>
								
								{
									Object.keys(selected_permissions).map(key=>{
										return <tr><td>{selected_permissions[key]}</td></tr>
									})
								}
								
							</tbody>
						</table>
						

						<button className="theme-btn btn-md mt-2">Create</button>
					</div>


					<div className="mt-3">
						<ModulePermissionCard icon={"person_add"} module_name={"New Employee"} />
					</div>
				</div>

				<div className="col-md-4">
						
				</div>


			</div>
		</div>
	);
}


export default RoleManagementPanel;