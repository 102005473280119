import React from 'react';
import { useHRISContext } from "../context";
import '../stylesheets/classic_theme.css';



const Header = ({toggleSidebar, value})=>{
	const { user_name } = useHRISContext();

	return(
		<div class="Header container-fluid sticky-top">
			
			<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0" />
			<div class="row panel-topbar">
				<div class="col-sm-12 d-flex align-items-center justify-content-between">

					<button className="btn" onClick={toggleSidebar}>
						<span class="material-symbols-outlined material-icons theme-panel-icon-user theme-color theme-no-margin">
							{value ? "menu_open":"menu"}
						</span>
					</button>

					<div className="profile d-flex align-items-center">
						<span class="pr-5 theme-color theme-no-margin">{user_name && user_name}</span>
						<span class="material-symbols-outlined material-icons theme-panel-icon-user theme-color theme-no-margin">
							account_circle
						</span>
					</div>
				</div>	
			</div>
		</div>

	);
}



export default Header;

