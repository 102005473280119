import React from 'react';
import { NavLink  } from "react-router-dom";


import MaterialIcon from './material_icon';

import "../stylesheets/classic_theme.css";


const IconedButtonV = ({icon, label, hint, action, extra_class})=>{
	return(
		<div class={"IconedButtonV " + extra_class}>
			<NavLink to={action}>
				<button class='btn right hint--rounded hint--bounce text-color-main' data-hint={hint}>
					<MaterialIcon icon_name={icon}/><br/>
					<span class={'iconed_btn_label ' + extra_class}>{label}</span>
				</button>
			</NavLink>
		</div>
	);
}


export default IconedButtonV;