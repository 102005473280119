import React, { useState } from 'react';
import { login } from "../auth";
import { useNavigate } from "react-router-dom";


import LoadingModal from "./loading_modal";


const LoginForm = ()=>{ 
	const navigate = useNavigate();
	const [email, setEmail] = useState(null);
	const [password, setPassword] = useState(null);
	const [is_loading, setIsLoading] = useState(false);


	const useAttemptLogin =  async (e)=>{
		e.preventDefault();
		setIsLoading(true);
		await login(email, password, navigate).then(success=>{
			setIsLoading(false);
		}).catch(error=>{
			setIsLoading(false);
		});
	}


	return(
		<div className="LoginForm theme-cardify">
			<p className="theme-header header-nm text-center">LUMINOUS HRIS</p>
			<p className="theme-header header-sm">Login</p>

			<LoadingModal is_loading={is_loading} />

			<form onSubmit={useAttemptLogin} method="get">
				<label className="form-label">Email</label>
				<input type="email" className="form-control theme-input-field" onChange={(e)=>(setEmail(e.target.value))} placeholder="Your Email"></input>

				<label className="form-label">Password</label>
				<input type="password" className="form-control theme-input-field" onChange={(e)=>(setPassword(e.target.value))} placeholder="Password"></input>

				<button className="theme-btn btn-expand mt-3">Log In</button>
			</form>
		</div>
	);
}

export default LoginForm;