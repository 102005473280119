import React, { useState, useEffect } from "react";
import { useHRISContext } from "../context";
import { postRequest, updateRequest } from "../auth";
import Swal from 'sweetalert2';
import LoadingModal from "../components/loading_modal";
import { getRequest } from "../auth";
import ProfileHeaderCardA from "../components/profile_components/profile_header_cardA";
import PersonalInfoCardA from "../components/profile_components/personal_info_cardA";





const UserDashboard = ()=>{
	const {user_id} = useHRISContext();
	const [is_panel_loading, setPanelLoading] = useState(false);
	const [user_registers_data, setUserRegisterData] = useState();
	const [user_account_data, setUSerAccountData] = useState();




	/******* CALLBACKS *******/
	const handleTimeIn = (e) => {
		e.preventDefault();
		setPanelLoading(true);
		const timeIn = async (data)=>{
			await postRequest("/attendance/" , data).then(response=>{
				if(response.status === 201){
					setPanelLoading(false);
					Swal.fire({
						icon: "success",
						title: "You are Present",
						text: "Time in successfully",
					});
				} else if(response.status === 208){
					setPanelLoading(false);
					Swal.fire({
						icon: "warning",
						title: "Opss!",
						text: "You already timed in",
					});
				} else if(response.status === 204){
					setPanelLoading(false);
					Swal.fire({
						icon: "warning",
						title: "Opss!",
						text: "You Dont have any shift set for today",
					});
				}
			}).catch(error=>{
				setPanelLoading(false);
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Error Timing in",
				});
			})
		}

		const data = {
			type:"in", 
			user_account_id:user_id,
		} 

		if(user_id === undefined || user_id === null){
			setPanelLoading(false);
			Swal.fire({
				icon: "error",
				title: "Error",
				text: "Error no user id present",
			});
		} else {
			setPanelLoading(false);
			timeIn(data);
		}
	}


	const handleTimeOut = (e)=>{
		e.preventDefault();
		setPanelLoading(true);
		const timout = async()=>{
			await updateRequest("/attendance/", {}).then(response=>{
				if(response.status === 200){
					setPanelLoading(false);
					Swal.fire({
						icon: "success",
						title: "Timed out",
						text: "Time out successfully",
					});
				} else if(response.status === 204){
					setPanelLoading(false);
					Swal.fire({
						icon: "warning",
						title: "Opss!",
						text: "This occur because you may haven't timed in yet or Dont have a shift today",
					});
				} else if(response.status === 208){
					setPanelLoading(false);
					Swal.fire({
						icon: "warning",
						title: "Opss!",
						text: "You already timed out",
					});
				}
			}).catch(error=>{
				setPanelLoading(false);
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Error while setting time out",
				});
			})
		}

		timout();
	}



	useEffect(()=>{
		const getUserRegisterdata = async()=>{
			await getRequest("/register/user/" + user_id).then(response=>{
				if(response.status === 200){
					setUserRegisterData(response.data);
				}
			}).catch(error=>{
				alert("error getting user register: " + error.status);
			})
		}

		const getUserAccount = async()=>{
			await getRequest("/register/account?user=" + user_id).then(response=>{
				if(response.status === 200){
					setUSerAccountData(response.data);
				}
			}).catch(error=>{
				alert("error getting account data: " + error.status);
			})
		}

		getUserRegisterdata();
		getUserAccount();
	}, [user_id]);


	return (
		<div className="container-fluid">
			<LoadingModal is_loading={is_panel_loading}/>

			<div className="row mb-2">
				<div className="col-md-12">
					<p className="theme-section-title title-nm">User Dashboard</p>
				</div>
			</div>


			<ProfileHeaderCardA 
				image_path={user_registers_data && user_registers_data["profile_image"]} 
				full_name={user_registers_data && user_registers_data["first_name"] + " " + user_registers_data["middle_name"] + ", " + user_registers_data["last_name"]} 
				job_title={user_registers_data && user_registers_data["job_title_name"]} 
				account_email={user_account_data && user_account_data["email"]} 
			/>
			

			<div className="row mt-2">
				<div className="col-md-8">
					<PersonalInfoCardA 
						birthdate={user_registers_data && user_registers_data["birthdate"]}
						age={user_registers_data && user_registers_data["age"]}
						civil_status={user_registers_data && user_registers_data["civil_status"]}
						gender={user_registers_data && user_registers_data["gender"]}
						contact_number={user_registers_data && user_registers_data["contact_number"]}
						email={user_registers_data && user_registers_data["email"]}
					/>
				</div>
				<div className="col-md-4">
					<div className="row theme-cardify card-expand">
						<div className="col-md-12">
							<button className="theme-btn btn-expand" onClick={(e)=>(handleTimeIn(e))}>Time In</button>
							<button className="theme-btn btn-expand" onClick={(e)=>(handleTimeOut(e))}>Time Out</button>
						</div>
					</div>
				</div>
			</div>

		</div>
	);
}


export default UserDashboard;