import React from "react";


const SmallCard = ({title, body})=>{
	return (
		<div className="theme-cardify card-w-expand mt-1 mt-2">
			<p className="theme-text-sm">{ title }</p>
			<p className="text-center">{ body }</p>
		</div>
	);
}

export default SmallCard;