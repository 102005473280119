import React from 'react';
import { createBrowserRouter, Route, createRoutesFromElements, RouterProvider } from 'react-router-dom';
//import GuardedRoute from "./pages/utils/route_guard";
//import { useHRISContext } "./pages/context";


import Root from "./pages/root";
import InDevelopmentPanel from "./pages/panels/indevelopment_panel";

import EmployeeLoginPage from "./pages/employee_login_page";
import MoreOptionsPanel from "./pages/panels/more_options_panel";
import AttendancePanel from "./pages/panels/attendance_panel";
import EmployeeList from "./pages/panels/employee_list_panel";
import EmployeeAccountManagerPanel from "./pages/panels/employee_account_manager";
import ShiftManagementPanel from "./pages/panels/shift_management";
import JobTitlePanel from "./pages/panels/job_titles_panel";
import RequestLeavePanel from "./pages/panels/request_leave_panel";
import ManageLeavePanel from "./pages/panels/manage_leave_panel";
import RoleManagementPanel from "./pages/panels/role_management_panel";



//import AdminDashboard from "./pages/panels/admin_dashboard";
import UserDashboard from "./pages/panels/user_dashboard";


import DebugPanel from "./pages/debug_pages/debug_panel";


const router = createBrowserRouter(

    createRoutesFromElements(
        <Route path="/" element={<Root />}>
          <Route path="/login" element={<EmployeeLoginPage />} />
          <Route path="/dashboard/admin" element={<UserDashboard />} />
          <Route path="/dashboard/user" element={<UserDashboard />} />
          <Route path="/more/options" element={<MoreOptionsPanel />} />
          <Route path="/attendace" element={<AttendancePanel />} />
          <Route path="/employee-list" element={<EmployeeList />} />
          <Route path="/manage/employee" element={<EmployeeAccountManagerPanel />} />
          <Route path="/shift-management" element={<ShiftManagementPanel />} />
          <Route path="/job-title" element={<JobTitlePanel />} />
          <Route path="/request-leave" element={<RequestLeavePanel />} />
          <Route path="/manage-leave" element={<ManageLeavePanel />} />
          <Route path="/manage-roles" element={<RoleManagementPanel />} />


          <Route path="/debug" element={<DebugPanel />} />

          <Route path="/notice/indevelopment" element={<InDevelopmentPanel />} />
        </Route>
      )
  )


function App() {
  return (
    <div className="App">
      <RouterProvider router={router}/>
    </div>
  );
}

export default App;
