import React, { useEffect, useState } from 'react';
import { getRequest } from "../auth";
import { formatTime } from "../utils/utils";






const AttendancePanel = ()=>{
	const [attendance_record, setAttendanceRecord] = useState();

	const getAttendance = async()=>{
		await getRequest("/attendance").then(response=>{
			if(response.status === 200){
				setAttendanceRecord(response.data);
			}
		}).catch({
			
		});
	}



	


	useEffect(()=>{
		getAttendance();
	},[]);


	return(
		<div className="AttendancePanel container-fluid">
			<div className="row">
				<div className="col-md-12">
					<table className="table">
						<thead>
							<tr>
								<th>present</th>
								<th>time in</th>
								<th>Early Timein</th>
								<th>Late Timein</th>
								<th>time out</th>
								<th>Over Time</th>
							</tr>
						</thead>
						<tbody>
							{!attendance_record && "Loading..."}
							{	attendance_record &&
								Object.keys(attendance_record).map(key=>{
									return <tr>
										<td>{attendance_record[key]["attendace_is_present"] ? "Present":"Absent"}</td>
										<td>{formatTime(attendance_record[key]["time_in"])}</td>
										<td>
											
											{
												attendance_record[key]["early_timein"] > 60 ? 
												(attendance_record[key]["early_timein"] / 60).toFixed(0) + " Hours"
												: 
												attendance_record[key]["early_timein"].toFixed(0) + " Minutes"
											} 
										</td>
										<td>
											{
												attendance_record[key]["late_timein"] > 60 ? 
												(attendance_record[key]["late_timein"] / 60).toFixed(0) + " Hours"
												: 
												attendance_record[key]["late_timein"].toFixed(0) + " Minutes"
											} 
											
										</td>
										<td>{attendance_record[key]["time_out"] !== null && attendance_record[key]["time_out"] !== "" &&  formatTime(attendance_record[key]["time_out"])}</td>
										<td>
											{	
												attendance_record[key]["overtime"] > 60 ? 
												(attendance_record[key]["overtime"] / 60).toFixed(0) + " Hours"
												: 
												attendance_record[key]["overtime"] !== null ? attendance_record[key]["overtime"] + " Minutes": ""
											} 
										</td>
									</tr>
								})
							}
						</tbody>

					</table>
				</div>
			</div>
		</div> 
	);
}


export default AttendancePanel;