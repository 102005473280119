import React from 'react';
import { logout } from "../auth";
import { useNavigate } from "react-router-dom";


import '../stylesheets/sidebar.css';
import MaterialIcon from './material_icon';
import IconedButtonV from './iconed_btn_v';


const SideBar = ()=>{
	const navigate = useNavigate();

	const logoutUser = async (e)=>{
		e.preventDefault();
		await logout().then(reponse=>{
			navigate("/login", {});
		}).catch(error =>{
			console.warn("error logging out: " + error)
		});
	}

	return (
		<div class='SideBar sidebar'>
			<div class="sidebar-icons d-flex flex-column justify-content-between">

				<div className="mt-5">
					<IconedButtonV icon={"widgets"} hint={"More Options"} label={"More"} action={"/more/options"} extra_class={"no-padding"}/>
				</div>

				<div>
					<IconedButtonV icon={"person"} hint={"User dashboard"} label={"Dashboard"} action={"/dashboard/admin"} extra_class={"no-padding"}/>

					<IconedButtonV icon={"event_available"} hint={"View attendance record"} label={"Attendance"} action={"/attendace"} extra_class={"no-padding"}/>

					<IconedButtonV icon={"account_balance_wallet"} hint={"View salary information"} label={"Salary"} action={"/notice/indevelopment"} extra_class={"no-padding"}/>

					<IconedButtonV icon={"free_cancellation"} hint={"Manage Leaves"} label={"Leaves"} action={"/request-leave"} extra_class={"no-padding"}/>


					<IconedButtonV icon={"settings"} hint={"Application Settings"} label={"Settings"} action={"/notice/indevelopment"} extra_class={"no-padding"}/>
				</div>

				<div>
					<form onSubmit={logoutUser} class="IconedButtonV no-padding">
						<button type="submit" class='btn right hint--rounded hint--bounce text-color-main' data-hint="Logout">
							<MaterialIcon icon_name="logout"/><br/>
							<span class="iconed_btn_label no-padding">Logout</span>
						</button>
					</form>
				</div>
			</div>
		</div>
	);
}

export default SideBar;